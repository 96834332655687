<template>
    <div class="component-container">
        <div class="x-block">
            <div v-html="oembedObject.rawData.html"></div>
        </div>
    </div>
</template>

<script>
var TwitterWidgetsLoader = require('twitter-widgets');

export default {
    name: 'X',

    props: [
        'cmsBlock'
    ],

    components: {
        
    },

    computed: {
        oembedObject() {
           return JSON.parse(this.cmsBlock.oembedContent)
       } 
    },

    mounted() {
        TwitterWidgetsLoader.load()
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .x-block {
        max-width: 500px; 
        margin: 0 auto;
    }

}


</style>
